var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"col-md-4 explore-us"},[_c('div',{staticClass:"footer-right"},[_c('h3',[_vm._v("Explore Us")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ul',{staticClass:"explore-link"},[_vm._m(2),_c('p',[_c('a',{attrs:{"href":"/booking"},on:{"click":function($event){$event.preventDefault();return _vm.handleRestrictedClick('booking')}}},[_vm._v("Booking")])]),_vm._m(3)])]),_c('div',{staticClass:"col-md-6"},[_c('ul',{staticClass:"explore-link"},[_c('p',[_c('a',{attrs:{"href":"/club"},on:{"click":function($event){$event.preventDefault();return _vm.handleRestrictedClick('club')}}},[_vm._v("Club")])]),_vm._m(4)])])])])]),_vm._m(5),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"footer-left"},[_c('img',{staticClass:"footer-img",attrs:{"src":require("../assets/tagline-logo.svg"),"alt":"","srcset":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"footer-center"},[_c('h3',[_vm._v("Contact Us")]),_c('address',[_c('p',[_c('img',{attrs:{"src":require("../assets/phone.png"),"alt":"","srcset":""}}),_vm._v(" +92-327-0877831 ")]),_c('p',[_c('img',{attrs:{"src":require("../assets/email.png"),"alt":"","srcset":""}}),_vm._v(" fieldfellas@gmail.com ")]),_c('p',[_c('img',{attrs:{"src":require("../assets/location.png"),"alt":"","srcset":""}}),_vm._v(" PGECHS PH-II, Lahore ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/"}},[_vm._v("Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/contact"}},[_vm._v("Contact Us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/"}},[_vm._v("About Us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 d-flex"},[_c('div',{staticClass:"footer-below pt-3"},[_c('h3',{staticClass:"mb-2"},[_vm._v("Let's Stay In Touch")]),_c('ul',{staticClass:"social-icons"},[_c('li',[_c('a',{attrs:{"href":"#"}},[_c('img',{staticClass:"twitter",attrs:{"src":require("../assets/facebook.png"),"alt":"","srcset":""}})])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_c('img',{staticClass:"twitter",attrs:{"src":require("../assets/twitter.png"),"alt":"","srcset":""}})])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_c('img',{staticClass:"twitter",attrs:{"src":require("../assets/instagram.png"),"alt":"","srcset":""}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-md-4 jazz-logo"},[_c('p',[_vm._v("Pay with")]),_c('img',{staticStyle:{"height":"100px"},attrs:{"src":require("../assets/jazz-cash.png"),"alt":""}})]),_c('div',{staticClass:"col-md-4 copyright"},[_c('p',[_vm._v("© 2023 Field Fellas. All rights reserved.")])])])])
}]

export { render, staticRenderFns }