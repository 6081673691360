<template>
  <div id="app">
    <NavbarView />
    <router-view />
    <TapTop />
    <FooterView />
    <!-- <ComingSoon /> -->
    <!-- v-if="!isLoginPage" -->
    <div class="side-badge">
      <div class="text-container">Pay with</div>
      <div class="image-container">
        <img src="@/assets/jazz-cash.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import NavbarView from "./components/NavbarView.vue";
import FooterView from "./components/FooterView.vue";
import TapTop from "./components/TapTop.vue";
// import ComingSoon from "./components/coming-soon.vue";

export default {
  name: "App",
  components: {
    NavbarView,
    FooterView,
    TapTop,
    // ComingSoon
  },
  computed: {
  },

  beforeDestroy() {
    this.this.$store.state.user = null;
  },

  created() {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    if (token && user) {
      try {
        // Update Vuex store with user data
        const parsedUser = JSON.parse(user);
        this.$store.commit("setUser", parsedUser);
        // Optionally, validate token on the server-side and handle expiration
      } catch (error) {
        this.$showErrorMessageToast(error);
        // Handle the invalid JSON case (e.g., log the user out or clear the storage)
        localStorage.removeItem("user");
      }
    } else if (this.$route.path !== "/") {
      // Redirect to login only if not already on login page
      this.$router.push("/").catch(err => {
        // Handle navigation errors
        if (err.name !== "NavigationDuplicated") {
          this.$showErrorMessageToast(err);
        }
      });
    }
  }

};
</script>

<style lang="scss">
@import "vue-multiselect/dist/vue-multiselect.min.css";


.btn-danger {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  color: white !important
}

.btn-danger:hover {
  background-color: #bb2d3b !important;
  border-color: #bb2d3b !important;
}

.multiselect {
  min-height: 38px !important;
}

.multiselect__placeholder {
  padding: 0 !important;
  margin: 0 !important;
  color: #555555 !important;
  font-size: 1rem !important;
}

.multiselect__tags {
  cursor: pointer;
  padding: .5rem 0px 0px .75rem;
  border-radius: 10px !important;
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s, ease-in-out;
  min-height: 38px;
}

.multiselect__tag {
  margin-right: 5px !important;
  margin-bottom: 0px !important;
}

.multiselect__select {
  width: 20px;
  height: 40px;
}

.multiselect--disabled {
  background: transparent;
}

.multiselect--disabled .multiselect__select {
  background: transparent;
}

.slogan-text {
  font-size: 10rem;
  font-weight: bold;
  font-style: italic;
  -webkit-text-stroke: 3px #00853e;
  color: white;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
  white-space: nowrap;
  will-change: transform;
}

.btn-secondary {
  background-color: #00853e !important;
  border-color: #00853e !important;
  font-weight: bold;
  color: white !important;
}

.btn-secondary:hover {
  background-color: #404041 !important;
  border-color: #404041 !important;
  color: white !important;

}

.img-fluid>.card-img,
.card-img-top {
  width: 300px !important;
  height: 250px !important;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .slogan-text {
    font-size: 9rem;
  }
}

@media (max-width: 991px) {
  .slogan-text {
    font-size: 7rem;
  }

  .col {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .slogan-text {
    font-size: 5.5rem;
  }
}

@media (max-width: 575px) {
  .slogan-text {
    font-size: 4.5rem;
  }
}

@media (max-width: 479px) {
  .slogan-text {
    font-size: 3.5rem;
  }

  ::v-deep .nav-item {
    margin-bottom: 0.5rem !important;
  }
}

@media (max-width: 375px) {
  .slogan-text {
    font-size: 3rem;
  }
}
</style>
