<template>
  <div class="main-container mb-5">
    <WebLoader v-if="loading" />
    <ParallaxText />
    <b-container v-if="filteredBookings.length > 0">
      <b-row>
        <b-col md="6" class="flex-responsive mb-5" v-for="booking in filteredBookings" :key="booking.id">
          <b-card class="booking-card img-fit" :title="booking.club_name" :img-src="booking.turf_image"
            img-alt="Turf Image">

            <div class="booking-info">

              <div class="row">
                <div class="pb-2 col-6">
                  <strong>Booking Id:</strong>
                </div>
                <div class="pb-2 col-6 left-border">
                  {{ booking.id }}
                </div>
                <div class="pb-2 col-6">
                  <strong>Turf Name:</strong>
                </div>
                <div class="pb-2 col-6 left-border">
                  {{ booking.turf_name }}
                </div>
                <div class="pb-2 col-6">
                  <strong>Booking Date:</strong>
                </div>
                <div class="pb-2 col-6 left-border">
                  {{ formatBookingDate(booking.date) }}
                </div>
                <div class="pb-2 col-6">
                  <strong>Time Slot:</strong>
                </div>
                <div class="pb-2 col-6 left-border d-flex g-5 justify-content-start flex-wrap m-0 px-07">
                  <div>
                    {{ mergedTimeSlots
                      .find((b) => b.id === booking.id)
                      .timeslots.map((slot) => slot.time_slot)
                      .join(", ") }}
                  </div>
                </div>
                <div class="pb-2 col-6">
                  <strong>Total Amount:</strong>
                </div>
                <div class="pb-2 col-6 left-border">
                  {{ booking.total_cost }}
                </div>
                <div class="pb-2 col-6">
                  <strong>Initial Deposit:</strong>
                </div>
                <div class="pb-2 col-6 left-border">
                  {{ booking.amount_taken_from_customer }}
                </div>
                <div class="pb-2 col-6">
                  <strong>Amount Payable (Onspot):</strong>
                </div>
                <div class="pb-2 col-6 left-border">
                  {{ booking.amount_sent_to_vendor }}
                </div>

                <div class="payment-buttons">
                  <b-button :variant="isTimeUp(booking.id) ? 'secondary' : 'primary'" :disabled="isTimeUp(booking.id)"
                    @click="makePayment(booking.id)">
                    Pay Now
                  </b-button>
                  <b-button :variant="isTimeUp(booking.id) ? 'secondary' : 'danger'" :disabled="isTimeUp(booking.id)"
                    @click="cancelBookingModal(booking.id)">
                    Cancel Booking
                  </b-button>
                </div>
                <div class="countdown-timer">
                  <strong class="text-center">
                    <i class="fas fa-clock"></i>
                    Remaining Time:</strong>
                  <CountDown :endTime="booking.timer" @time-up="handleTimeUp(booking.id)" />
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else>
      <b-row>
        <b-col md="12">
          <h1 class="py-5 h-100 text-center d-flex justify-content-center align-items-center">
            <strong>
              <i class="fas fa-exclamation-triangle text-danger"></i>
              No Bookings Found
            </strong>
          </h1>
        </b-col>
        <b-col md="12" class="text-center">
          <b-button variant="danger" @click="$router.push({ name: 'home' })" class=" btn-danger my-5">Back to
            Home</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import CountDown from "./CountDown.vue";
import axios from "axios";
import { BASE_URL } from "@/../config";
import Swal from "sweetalert2";
import ParallaxText from "../ParallaxText.vue";


export default {
  components: {
    CountDown,
    ParallaxText

  },
  data() {
    return {
      bookings: [],
      timeUpBookings: [],
      loading: false,
      jazzCashNumber: ""
    };
  },
  computed: {
    filteredBookings() {
      return this.bookings.filter(
        (booking, index, self) =>
          booking.booking_status === "temp_booked" &&
          self.findIndex((b) => b.id === booking.id) === index
      );
    },

    mergedTimeSlots() {
      return this.bookings.map((booking) => {
        const mergedSlots = [];
        const sortedSlots = booking.timeslots.sort((a, b) => {
          return this.getTime(a.time_slot) - this.getTime(b.time_slot);
        });

        let currentSlot = sortedSlots[0];

        for (let i = 1; i < sortedSlots.length; i++) {
          const slot = sortedSlots[i];

          if (this.isAdjacent(currentSlot.time_slot, slot.time_slot)) {
            // Merge consecutive slots
            currentSlot.time_slot = this.mergeSlots(
              currentSlot.time_slot,
              slot.time_slot
            );
          } else {
            // Push non-consecutive slots as-is
            mergedSlots.push(currentSlot);
            currentSlot = slot;
          }
        }

        // Add the last slot
        mergedSlots.push(currentSlot);

        // Ensure all merged slots remain as separate objects
        return { ...booking, timeslots: mergedSlots };
      });
    },

  },
  methods: {
    formatBookingDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.toLocaleString("en-US", { month: "short" }); // Get abbreviated month name
      const weekday = date.toLocaleString("en-US", { weekday: "short" }); // Get abbreviated weekday name
      return `${day} ${month} - ${weekday}`; // Format as "26 Dec - Thu"
    },
    cancelBookingModal(bookingId) {
      Swal.fire({
        title: "Booking Cancellation!",
        text: "Are you sure you want to cancel this booking?",
        icon: "warning",
        iconColor: "#d33",
        confirmButtonText: "OK",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
      }).then((result) => {
        if (result.isConfirmed) {
          this.cancelBooking(bookingId); // Call cancelBooking if confirmed
        }
      });
    },
    routeToPayment() {
      this.$router.push("/pending");
    },
    getTime(timeSlot) {
      const [start] = timeSlot.split("-");
      return new Date(`1970-01-01T${start}Z`).getTime();
    },

    isAdjacent(slot1, slot2) {
      const [end1] = slot1.split("-").reverse();
      const [start2] = slot2.split("-");
      return end1 === start2;
    },
    mergeSlots(slot1, slot2) {
      const [start1] = slot1.split("-");
      const [end2] = slot2.split("-").reverse();
      return `${start1}-${end2}`;
    },
    routeToPending() {
      this.$router.push("/payment");
    },
    routeToBack() {
      this.$router.push("/tslot");
    },

    makePayment(bookingId) {
      const selectedBooking = this.filteredBookings.find((booking) => booking.id === bookingId);

      if (!selectedBooking) {
        this.$showErrorMessageToast("Booking not found!");
        return;
      }

      Swal.fire({
        position: "bottom",
        html: `
        <h5 style="color:black; font-weight:600;">Complete Your Payment via JazzCash</h5>
      <div class="swal-image-container">
        <img style="height: 70px;" alt="Payment Image" src="${require('@/assets/images/jaazcash-logo.jpg')}" />
      </div>
      <p style="font-size: 1rem;"><strong>Initial Deposit:</strong> ${selectedBooking.amount_taken_from_customer}</p>
      <p style="font-size: 1rem;"><strong>Amount Payable (Onspot):</strong> ${selectedBooking.amount_sent_to_vendor}</p>
      <p class="mobile-label" style="font-size: 1rem; text-align: start; margin-top: .825rem; margin-bottom: 2px;">Enter your JazzCash mobile number:</p>
      <input class="cnic-input" style="width: 100%; margin: 0px; font-size: 1rem; padding: .2rem;" type="text" maxlength="11" id="jazzCashNumber" class="swal2-input" placeholder="Enter your 11-digit JazzCash number">
      <p class="cnic-label" style="font-size: 1rem; text-align: start; margin-top: .825rem; margin-bottom: 2px;">Enter the last 6 digits of your CNIC:</p>
      <input class="cnic-input" style="width: 100%; margin: 0px; font-size: 1rem; padding: .2rem;" type="text" maxlength="6" id="cnicNumber" class="swal2-input" placeholder="Enter your 6-digit CNIC">
    `,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        className: "swal-back",
        customClass: {
          title: "custom-swal-title",
          popup: "swal-back-popoup",
          container: "custom-swal-container",
          confirmButton: "custom-confirm-button",
          cancelButton: "custom-cancel-button",
        },
        didOpen: () => {
          // Attach the event listener in Vue's context
          document.getElementById('cnicNumber').addEventListener('input', this.updateMaskedCnic);
        },
        preConfirm: () => {
          const number = Swal.getPopup().querySelector("#jazzCashNumber").value;
          const cnic = Swal.getPopup().querySelector("#cnicNumber").value;

          if (!number || number.trim() === "") {
            Swal.showValidationMessage("Please enter your JazzCash mobile number");
            return false;
          }
          if (!/^\d{11}$/.test(number)) {
            Swal.showValidationMessage("Please enter a valid 11-digit mobile number");
            return false;
          }
          if (!cnic || cnic.trim() === "") {
            Swal.showValidationMessage("Please enter the last 6 digits of your CNIC");
            return false;
          }
          if (!/^\d{6}$/.test(cnic)) {
            Swal.showValidationMessage("Please enter a valid 6-digit CNIC");
            return false;
          }

          return { mobile_no: number, cnic: cnic }; // Return mobile number and the last 6 digits of CNIC
        },
        willClose: () => {
          document.getElementById('cnicNumber').removeEventListener('input', this.updateMaskedCnic);
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const { mobile_no, cnic } = result.value;

          const payload = {
            booking_id: bookingId,
            customer_id: this.$store.state.user.id,
            mobile_no: mobile_no,
            cnic: cnic, // Include only the 6 digits of CNIC in the payload
          };
          console.log("Payload is:", payload);

          this.loading = true;

          axios
            .post(`${BASE_URL}/create-payment`, payload, {
              headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((response) => {
              this.$showSuccessMessageToast(response.data.message);
              this.fetchCustomerBookings();
              this.$router.push({ name: "my-profile" });
            })
            .catch((error) => {
              this.$showErrorMessageToast("Failed to initiate payment. Please try again.", error);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    cancelBooking(bookingId) {
      this.loading = true;
      axios
        .post(
          `${BASE_URL}/cancel-customer-booking`,
          { booking_id: bookingId },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          }
        )
        .then((response) => {
          this.bookings = this.bookings.filter((booking) => booking.id !== bookingId);
          this.$showSuccessMessageToast(response.data.message);
          this.fetchCustomerBookings();
        })
        .catch((error) => {
          this.$showErrorMessageToast("Failed to cancel booking. Please try again.", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchCustomerBookings() {
      this.loading = true;
      axios
        .get(`${BASE_URL}/get-customer-booking`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          this.bookings = response.data.data;
        })
        .catch((error) => {
          this.$showErrorMessageToast("Error in fetching bookings", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleTimeUp(bookingId) {
      if (!this.timeUpBookings.includes(bookingId)) {
        this.timeUpBookings.push(bookingId);
        this.cancelBooking(bookingId);
      }
    },
    isTimeUp(bookingId) {
      return this.timeUpBookings.includes(bookingId);
    },
  },
  mounted() {
    this.fetchCustomerBookings();
  },
};
</script>

<style scoped>
.img-fit>img {
  object-fit: cover !important;
}

.countdown-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.px-07 {
  padding-inline: 0.7rem;
}

.booking-card {
  width: 500px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.card-img {
  border-bottom-left-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
}

.booking-card img {
  max-height: 260px;
}

.booking-info p {
  margin: 5px 0;
}

.payment-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  gap: 0.5rem;
}

.payment-buttons .btn {
  margin: 5px 0;
}

.card-title {
  text-align: center;
  font-weight: 700;
  color: #00863e;
  font-size: 2rem;
}

.booking-info {
  color: #404041;
  font-size: 1.1rem;
}

.left-border {
  border-left: 1px solid #404041;
}

@media (max-width: 1200px) {
  .card {
    max-width: 450px;
  }

  .booking-card {
    width: 100%;
  }

  .booking-card img {
    max-height: 230px;
  }

  .card-title {
    font-size: 1.5rem;
  }

  .booking-info {
    font-size: 1rem;
  }

  .flex-responsive {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .card {
    max-width: 400px !important;
  }

  .booking-card {
    width: 100%;
  }

  .booking-card img {
    max-height: 200px;
  }

  .card-title {
    font-size: 1.5rem;
  }

  .booking-info {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .card {
    max-width: 375px;
  }

  .booking-card {
    width: 100%;
  }

  .booking-card img {
    max-height: 200px;
  }

  .card-title {
    font-size: 1.5rem;
  }

  .booking-info {
    font-size: 1rem;
  }
}

@media (max-width: 575.5px) {

  /* .card {
    max-width: 350px;
  } */
  /* .booking-card {
    width: 100%;
  } */
  .card-title {
    font-size: 1.2rem;
  }

  .booking-info {
    font-size: 0.9rem;
  }

  /* .left-border {
    border-left: none !important;
  } */
  .col-md-6 {
    border-left: none;
    /* max-width: 80%; */
  }

  .payment-buttons {
    margin-top: 0.5rem;
  }

  .payment-buttons .btn {
    font-size: 0.8rem;
  }
}

@media (max-width: 500px) {
  .card {
    max-width: 350px !important;
  }

  .booking-card {
    width: 100%;
  }

  .booking-card img {
    max-height: 200px;
  }

  .card-title {
    font-size: 1rem;
  }

  .booking-info {
    font-size: 0.8rem;
  }
}

@media (max-width: 400px) {
  .card {
    max-width: 310px !important;
  }

  .booking-card {
    width: 100%;
  }

  .booking-card img {
    max-height: 200px;
  }

  .card-title {
    font-size: 1rem;
  }

  .booking-info {
    font-size: 0.8rem;
  }
}

@media (max-width: 350px) {
  .card {
    max-width: 260px !important;
  }

  .booking-card {
    width: 100%;
  }

  .booking-card img {
    max-height: 200px;
  }

  .card-title {
    font-size: 0.9rem;
  }

  .booking-info {
    font-size: 0.7rem;
  }
}
</style>
