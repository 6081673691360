import { render, staticRenderFns } from "./BookingPayment.vue?vue&type=template&id=f13e7864&scoped=true&"
import script from "./BookingPayment.vue?vue&type=script&lang=js&"
export * from "./BookingPayment.vue?vue&type=script&lang=js&"
import style0 from "./BookingPayment.vue?vue&type=style&index=0&id=f13e7864&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f13e7864",
  null
  
)

export default component.exports