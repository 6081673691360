<template>
  <div class="home">
    <HomePage />
    <FeaturedTurf v-if="!isServiceProvider && !isAdmin" />
    <FrequentlyAskedQuestions />
    <AboutPage />

  </div>
</template>

<script>
import HomePage from "@/components/HomePage.vue";
import FeaturedTurf from "@/components/Customer/FeaturedTurf.vue";
import AboutPage from "@/components/AboutPage.vue";
import FrequentlyAskedQuestions from "@/components/FrequentlyAskedQuestions.vue";

export default {
  name: "HomeView",
  components: {
    HomePage,
    FeaturedTurf,
    AboutPage,
    FrequentlyAskedQuestions,
  },

  computed: {
    isServiceProvider() {
      return this.$store.state.user && this.$store.state.user.role_type === 'service_provider'
    },
    isAdmin(){
      return this.$store.state.user && this.$store.state.user.role_type === 'admin'
    }
  },
  methods: {


  },
  mounted() {
    //show toast message
    // this.$bvToast.toast("Welcome to Turf Booking App", {
    //   title: "Welcome",
    //   variant: "success",
    //   solid: true,
    //   autoHideDelay: 5000,
    // });
  },
};
</script>
